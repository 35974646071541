<template>
  <div class="app-container">
    <div class="filter-container">
      <el-input v-model="listQuery.keyword" placeholder="请输入食堂名称" style="width: 220px;" class="filter-item" clearable/>
      <el-button class="filter-item" type="primary" icon="el-icon-search" @click="handleFilter">搜索</el-button>
      <el-button class="filter-item" type="primary" icon="el-icon-edit" @click="dialogVisible = true">添加</el-button>
    </div>
    <el-table v-loading="listLoading" :data="list" element-loading-text="Loading" fit highlight-current-row>
      <el-table-column align="center" label="ID" width="60">
        <template slot-scope="scope">
          <!-- {{ scope.$index }} -->
          {{ scope.row.id }}
        </template>
      </el-table-column>
      <el-table-column label="学校" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.school.school_name }}
        </template>
      </el-table-column>
      <el-table-column label="食堂名称" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.canteen_name }}
        </template>
      </el-table-column>
      <el-table-column label="操作" fixed="right" align="center" width="230" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" @click="handleEdit(scope.row)">编辑</el-button>
          <el-button type="danger" size="mini" @click="handleDel(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination-container">
      <el-pagination :current-page="listQuery.page" :page-sizes="[5,10,20,30, 50]" :page-size="listQuery.limit" :total="total" background layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
    </div>

    <el-dialog :visible.sync="dialogVisible" :close-on-click-modal="false">
      <el-form ref="form" :model="form" label-position="left" label-width="70px">
        <el-form-item label="食堂名称" prop="name">
          <el-input v-model="form.canteen_name" placeholder="请输入食堂名称" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="saveData" :loading="btnLoading">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import request from "@/utils/request";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      list: null,
      total: null,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 10,
        keyword: "",
        school_id: "",
      },
      dialogVisible: false,
      btnLoading: false,
      form: {
        id: "",
        school_id: "",
        canteen_name: ""
      }
    };
  },
  created() {
    this.getList();
  },
  mounted() {},
  computed: {
    ...mapGetters(['schools','school_id']),
  },
  watch: {
    school_id(newVal, oldVal) {
      this.listQuery.school_id = newVal
      this.getList()
    },
    dialogVisible(newVal, oldVal) {
      // 编辑框一异隐藏，马上清除旧数据
      if (newVal === false) {
        this.form = {
          id: "",
          school_id: "",
          canteen_name: ""
        };
      }
    }
  },
  methods: {
    getList() {
      this.listQuery.school_id = this.school_id
      this.listLoading = true;
      request({
        url: "/api/backend/canteen/list",
        method: "get",
        params: this.listQuery
      }).then(response => {
        this.list = response.data.data;
        this.total = response.data.total;
        this.listLoading = false;
      });
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    handleEdit(item) {
      this.form = {
        id: item.id,
        school_id: item.school_id,
        canteen_name: item.canteen_name
      };
      this.dialogVisible = true;
    },
    handleDel(item) {
      this.$confirm("确定要删除该食堂吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        request({
          url: "/api/backend/canteen/del",
          method: "get",
          params: {
            id: item.id
          }
        }).then(() => {
          // 删除最后一条数据时无数据问题
          this.list.length <= 1 ? this.listQuery.page-- : false;
          this.getList();
          this.$message({
            type: "success",
            message: "操作成功!"
          });
        });
      });
    },
    saveData() {
      if (!this.school_id) {
        this.$message({
          type: "warning",
          message: "请选择学校"
        });
        return;
      } else {
        this.form.school_id = this.school_id
      }
      if (!this.form.canteen_name) {
        this.$message({
          type: "warning",
          message: "请输入食堂名称"
        });
        return;
      }
      this.btnLoading = true
      request({
          url: "/api/backend/canteen/save",
          method: "post",
          data: this.form
        }).then(() => {
          this.btnLoading = false
          this.dialogVisible = false
          this.getList();
          this.$message({
            type: "success",
            message: "操作成功!"
          });
        });
    }
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 6px;
}
</style>
